import "./App.scss";
import Header from "./Components/Header";
import Meme from "./Components/Meme";

export default function App() {
	return (
		<>
			<Header />
			<Meme />
		</>
	);
}
