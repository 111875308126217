import React, {useEffect, useState} from "react";
import "./Meme.scss";

export default function Meme() {
    
    /*** Getting new Meme ***/
    const [memeState, setMemeState] = useState({});

    useEffect( () => {
        fetch("https://api.imgflip.com/get_memes")
            .then(res => res.json())
            .then(memesData => setMemeState(memesData))
    }, [] )

    function getNewMeme() {
        const memesArr = memeState.data.memes; // Get array of meme objects
        const randomNumber = Math.floor( Math.random() * memesArr.length ) // Generate random number betweem 0 and number of items in memes array
        const randomImage = memesArr[randomNumber].url // Get object from array, setting index to random number, then get image url
        const randomName = memesArr[randomNumber].name // Get object from array, setting index to random number, then get name
        setMemeState( prevMeme => {
            return{
                ...prevMeme,
                randomImage: randomImage,
                randomName: randomName
            }
        })
    }
    /*** End getting new Meme ***/


    /*** Handling Form data ***/
    const [formData, setFormData] = useState({
        topText: "",
        bottomText: "",
    })

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData( prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            }
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
    }
    /*** End handling Form data ***/


    /*** Render component ***/
    return(
        <section className="meme-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="form">
                    <div className="form__group">
                        <input type="text" name="topText" placeholder="Top text" onChange={handleChange} value={formData.topText}/>
                        <input type="text" name="bottomText" placeholder="Bottom text" onChange={handleChange} value={formData.bottomText}/>
                    </div>

                    <button className="gradient" onClick={getNewMeme}>Get a new meme image</button>
                </form>

                {memeState && 
                    <div className="meme-section__meme-holder">
                        <img src={memeState.randomImage} alt={memeState.randomName} className="meme-image" />
                        <p className="top-text">{formData.topText}</p>
                        <p className="bottom-text">{formData.bottomText}</p>
                    </div>
                }
            </div>
        </section>
    )
    /*** End render component ***/
}