import React from "react";
import "./Header.scss"
import logo from "../images/troll-face.png";

export default function Header() {
    return(
        <header className="header gradient">
            <div className="container">
                <div className="header__inner">
                    <div className="header__left">
                        <a href="/"><img src={logo} alt="troll face" className="header__logo" /></a>
                        <p className="header__site-name"><a href="/">Meme Generator</a></p>
                    </div>

                    <div className="header__right">
                        <p><a href="https://roar-webdesign.co.uk/" target="_blank" rel="noreferrer">by Satinder Singh</a></p>
                    </div>
                </div>
            </div>
        </header>
    )
}